var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
         headline1: _vm.headline1,
         headline2: _vm.headline2,
         headline3: _vm.headline3,
         headline4: _vm.headline4,
         headline5: _vm.headline5,
         headline6: _vm.headline6,
         center: _vm.center,
     },style:(_vm.styleString)},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }