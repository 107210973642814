





































































import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class TextField extends Vue {
    @Prop({type: Number, default: -1, required: false}) size!: number
    @Prop({type: Number, default: -1, required: false}) weight!: number
    @Prop({type: String, default: '', required: false}) font!: string

    @Prop({type: Boolean, default: false, required: false}) bold!: boolean
    @Prop({type: Boolean, default: false, required: false}) center!: boolean

    @Prop({type: Boolean, default: false, required: false}) headline1!: boolean
    @Prop({type: Boolean, default: false, required: false}) headline2!: boolean
    @Prop({type: Boolean, default: false, required: false}) headline3!: boolean
    @Prop({type: Boolean, default: false, required: false}) headline4!: boolean
    @Prop({type: Boolean, default: false, required: false}) headline5!: boolean

    @Prop({type: Boolean, default: false, required: false}) headline6!: boolean
    get styleString() {
        let s = ''
        s += this.size > 0 ? 'font-size: '+this.size+'px; ' : ''

        if (this.bold) {
            s += 'font-weight: 700; '
        } else {
            s += this.weight > 0 ? 'font-weight: '+this.weight+'; ' : ''
        }

        s += this.font ? 'font-family: '+this.font+'; ' : ''
        return s
    }
}
