












import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class Container extends Vue {
    @Prop({type: Number, default: undefined, required: false}) width!: number
    @Prop({type: Number, default: undefined, required: false}) height!: number

    get styleStr() {
        let s = ''
        s += this.width ? 'width: ' + this.width + 'px; ' : ''
        s += this.height ? 'height: ' + this.height + 'px; ' : ''
        return s
    }

    getDiv() {
        return this.$refs.container
    }
}
