















import {Component, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Link from "@/components/fundamental/text/Link.vue"
import {RouteName} from "@/router/router"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"

@Component({
    components: {TextField, Column, Link, SpacerBox, Row}
})
export default class Footer extends Vue {

    routeNames = RouteName

    year = new Date().getFullYear()

}
