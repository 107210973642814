














































































































































































































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Link from "@/components/fundamental/text/Link.vue"
import Footer from "@/pages/Footer.vue"
import Navbar from "@/pages/Navbar.vue"

@Component({
    components: {Navbar, Footer, Link, SpacerBox, Row, TextField, Column}
})
export default class Privacy extends Vue {

}
