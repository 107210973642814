import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import Legal from "@/pages/Legal.vue"
import Privacy from "@/pages/Privacy.vue"
import Terms from "@/pages/Terms.vue"
import Blog from "@/pages/Blog.vue"

Vue.use(VueRouter)

export enum RouteName {
    home = 'home',
    blog = 'blog',
    legal = 'legal',
    privacy = 'privacy',
    terms = 'terms',
}

const routes = [
    {
        path: '/',
        name: RouteName.home,
        component: Home
    },
    {
        path: '/blog',
        name: RouteName.blog,
        component: Blog,
    },
    {
        path: '/legal',
        name: RouteName.legal,
        component: Legal,
    },
    {
        path: '/privacy',
        name: RouteName.privacy,
        component: Privacy,
    },
    {
        path: '/terms',
        name: RouteName.terms,
        component: Terms,
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router
