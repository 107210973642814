


























































































































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Link from "@/components/fundamental/text/Link.vue"
import {RouteName} from "@/router/router"
import Footer from "@/pages/Footer.vue"
import Navbar from "@/pages/Navbar.vue"
import Container from "@/components/fundamental/layout/Container.vue"

@Component({
    components: {Container, Navbar, Footer, Link, SpacerBox, Row, TextField, Column}
})
export default class Legal extends Vue {
    hero = require('@/assets/img/blog1.jpg')
    marioKart = require('@/assets/img/mario_kart_ghost.jpg')
    donkeyCarrot = require('@/assets/img/donkey_carrot.jpg')
    bikaOnBike = require('@/assets/img/bika_on_bike2.jpg')
    smartphoneMount = require('@/assets/img/smartphone_mount.jpg')
}
