

























































































































































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Link from "@/components/fundamental/text/Link.vue"
import {RouteName} from "@/router/router"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Footer from "@/pages/Footer.vue"
import Navbar from "@/pages/Navbar.vue"
import Container from "@/components/fundamental/layout/Container.vue"

@Component({
    components: {Container, Navbar, Footer, SpacerBox, Link, Row, TextField, Column}
})
export default class Home extends Vue {

    hero = require('@/assets/img/hero.jpg')

    karotteAppIcon = require('@/assets/img/karotte-icon.png')
    playstore = require('@/assets/img/google-play-badge.png')

    demoImg1 = require('@/assets/img/screenshot2-phone.jpg')
    demoImg2 = require('@/assets/img/screenshot1-phone.jpg')
    demoImg3 = require('@/assets/img/screenshot3-phone.jpg')
}
